<div class="grid grid-nogutter footer-all">
  <div class="col-12 md:col-12 lg:col-12 bottom-number-bar" [ngStyle]="{ display: checkDisplay }">
    <a [routerLink]="['']">
      <img src="./assets/sluchawki-biale.svg" alt="Sushimore - zadzwon" />
    </a>
    <span class="text">Zadzwoń i zamów
      <a class="number" href="tel:+48 663 600 900">663 600 900</a>
    </span>
  </div>
  <div class="col-12 md:col-12 lg:col-12 footer">
    <nav class="col-12 md:col-2 lg:col-2 first-list">
      <a [routerLink]="['']">Strona Głowna</a>
      <a [routerLink]="['/contact']">Kontakt</a>
      <a [routerLink]="['/order-online']">Zamów ON-LINE</a>
      <a target="_blank" href="./assets/Regulamin-akcji-promocyjnej-Paragon-pokazujesz-rabat-otrzymujesz_w restauracji-Sushi-More.pdf">Regulamin akcji promocyjnej "Paragon pokazujesz - rabat otrzymujesz"</a>
    </nav>
    <div class="col-12 md:col-2 lg:col-2 second-list">
      <a href="https://www.facebook.com/sushiandmorepl/" rel="nofollow" target="_blank">
        Facebook
      </a>
      <a href="https://www.instagram.com/sushimorepl/" rel="nofollow" target="_blank">
        Instagram
      </a>
    </div>
    <div class="col-12 md:col-3 lg:col-3 third-list">
      <a href="../../assets/Informacja o przetwarzaniu danych Sushi&More.pdf" rel="nofollow" target="_blank">
        Informacja o przetwarzaniu danych przez Sushi&More
      </a>
    </div>
    <div class="col-12 md:col-5 lg:col-5 sushi-more">
      <h3 class="cmat-h1">Sushi & More</h3>
      <p>
        Trafiłeś w wyjątkowe miejsce.. Znajdziesz tu wysmienite klasyczne sushi,
        ale jeśli szukasz czegoś więcej... mmmmm. Tak, mamy coś specjalnego.
      </p>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12 footer-child">
    <!-- <hr> -->
    <nav class="col-1 md:col-1 lg:col-1 logo">
      <a [routerLink]="['']">
        <img src="./assets/logo.webp" alt="Sushimore - logo" />
      </a>
    </nav>
    <div class="col-1 col-offset-9 md:col-1 md:col-offset-9 lg:col-1 lg:col-offset-9 arrow">
      <i class="fas fa-arrow-circle-up fa-3x" (click)="sharedService.scrollToTop()"></i>
    </div>
  </div>
</div>
